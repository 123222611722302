export const environment = {
    e2e: false,
    production: true,
    firebase: {
        apiKey: "AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU",
        authDomain: "dcsptest-sso.service.deloitte.ai",
        projectId: "dcsptest",
    },
    region: "europe-west1",
    brain_base_url: "https://deloitte-test.apigee.net/cwl-woven",
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle Compliance Questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "Henkel",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "Storefront",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: "https://aoh-qa.5064c7381d705f073d0a628e1f5c0a3b.cwl.camp",
    store_url: "https://store-qa.5064c7381d705f073d0a628e1f5c0a3b.cwl.camp",
    api_base_url: "https://deloitte-test.apigee.net",
    authParams: ["oidc.azure"],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    onboarding: {
        displayVideo: false,
        video_url: "",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:mahorst@deloitte.de?subject=Question%20regarding%20CAMPfire%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:mahorst@deloitte.de?subject=Question%20regarding%20CAMPfire%20Marketplace"
    },
    onboarding_options: {
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
